import React from "react";
import Form from 'react-bootstrap/Form';

function RadioBox({ label, selection, setSelection, options }) {
    const heading = {
        fontWeight: 600,
        color: "#CF9447",
        fontSize: 20
    }

    const radioBox = {
        borderColor: '#6F8AA2',
        borderStyle: 'solid',
        padding: 6,
        borderRadius: 6,
        borderWidth: 2,
        color: 'black',
        fontSize: 18
    }


    function onChange(event) {
        const selectedOption = options.find((option) => option.id == event.target.value)
        setSelection(selectedOption.id)
    }

    if (options.length === 0) {
        return;
    }

    return (
        <Form>
            <Form.Label style={heading}>{label}</Form.Label>
            {options.map((installation) =>
                <div style={radioBox} key={installation.id + installation.name} className="mb-3">
                    <Form.Check
                        feedbackTooltip={true}
                        key={installation.id + installation.name}
                        id={installation.id + installation.name}
                        onChange={onChange}
                        checked={selection ? selection == installation.id : false}
                        inline
                        label={installation.name + ' ' + (installation.price ? installation.price + ' kr' : '')}
                        name={'installation'}
                        type={'radio'}
                        value={installation.id}
                        className="text-center"
                    />
                </div>
            )
            }
        </Form >
    );
}

export default RadioBox;


