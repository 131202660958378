import React from "react";

const containerWrapper = {
    backgroundColor: '#434173',
    color: 'white',
}

function AboutUs() {
    return (
        <div id="about-us" style={containerWrapper}>
            <div className="container content">
                <div className="p-5">
                    <h2 className='text-center'>OM OSS</h2>

                    <div className='row mb-5'>
                        <p>Alegos EL grundades 2017 och är verksamma i hela Sverige. Vi jobbar bland annat
                            med Lidl, Ica och Stadium runt om i Sverige. Vi har alltid kunden i fokus och använder oss
                            utav produkter av bästa kvalite!</p>

                        <p>Vi ser inte problem, vi ser lösningar!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;


