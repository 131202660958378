import React from "react";
import { useNavigate } from "react-router-dom";
import chargingBoxes from "../../charging-boxes.json";

const containerWrapper = {
    backgroundColor: '#434173'
}

const card = {
    borderRadius: 18,
    cursor: 'pointer',
    marginRight: 15
}

const cardTitle = {
    color: 'rgb(207, 148, 71)',
    fontSize: 24,
    fontWeight: 600
}

const cardImage = {
    width: 230,
    alignSelf: 'center'
}

const price = {
    fontWeight: 600,
    fontSize: 23
}

const cardReadMoreBtn = {
    float: 'right'
}

const text = {
    fontSize: 20,
    marginBottom: 0
}

const textContainer = {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 10,
    width: 'fit-content',
    margin: 'auto'
}

function ChargingBox() {
    const navigate = useNavigate();

    function onClickCard(boxId) {
        navigate(`/laddbox/${boxId}`)
    }

    function toCurrency(price) {
        return new Intl.NumberFormat("sv-SE", {
            style: "currency",
            currency: "SEK"
        }).format(price);
    }


    return (
        <div id="charging-boxes" style={containerWrapper}>
            <div className="container content">
                <div className="pt-5 pb-5">
                    <h2 className='text-center'>LADDBOXAR</h2>
                    <div className="mb-3" style={textContainer}>
                        <p style={text} className="text-center">
                            <a target="_blank" href="images/price-list.pdf">Här</a>
                            <span> </span> hittar du prislista för våra tilläggstjänster som kan tillkomma vid installation.
                        </p>
                    </div>
                    <div className="row">
                        {chargingBoxes.map((box) => {
                            return (
                                <div key={box.id} onClick={() => onClickCard(box.id)} style={card} className="card col-sm-3 col-12 mr-3 mb-3 flex-grow-1 p-4">
                                    <img style={cardImage} className="img-fluid" src={`/images/${box.image}`} />
                                    <hr />
                                    <div className="card-body">
                                        <div className="mb-4">
                                            <h5 className='text-center' style={cardTitle}>{box.title}</h5>
                                            <p className="card-text">{box.preamble}</p>
                                        </div>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-7">
                                                <h3 style={price}>Fr. {toCurrency(box.price)}</h3>
                                            </div>
                                            <div className="col-5">
                                                <a href={`/#/laddbox/${box.id}`} style={cardReadMoreBtn} className="btn btn-primary">Läs mer</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChargingBox;


