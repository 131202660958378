import React from "react";

function PriceList() {
    return (
        <div id="price-list" className="container content">
            <h2 className='text-center'>PRISLISTA</h2>
            <p className="text-center">
                <a target="_blank"  href={"images/price-list.pdf"}>Här</a>
                <span> </span> hittar du prislista för våra tilläggstjänster.
            </p>
        </div>
    );
}

export default PriceList;


