import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { isShowBanner, setIsShowBanner } from "../../sessionstorage"
import { useLocation } from "react-router-dom";

const backgroundImg = {
    backgroundImage: 'url("/images/home.jpg")',
    backgroundSize: 'cover',
    padding: 0,
    height: '100%'
}

const overlay = {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: '100%'
}

const logo = {
    display: 'flex',
    margin: 'auto',
    paddingTop: 100
}

const seal = {
    height: '200px',
    opacity: 0.5
}

const heading = {
    textAlign: 'center',
    fontWeight: 700,
    fontFamily: 'auto',
}

const secondHeading = {
    color: 'white',
    textAlign: 'center',
    fontFamily: 'auto',
}

const image = {
    width: '80%'
}

function Start() {
    const { hash } = useLocation();
    const [show, setShow] = useState(isShowBanner);
    const navigate = useNavigate();

    useEffect(() => {
        hash && document.querySelector(hash).scrollIntoView()
    }, [hash === '#charging-boxes'])

    function onHide() {
        setShow(false)
        setIsShowBanner(false)
    }

    function onClickModal() {
        onHide()
        navigate("/laddbox/1")
    }

    return (
        <div id="start" className="container-fluid" style={backgroundImg}>
            <Modal size="lg" animation={true} autoFocus={true} show={false} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Superkampanj!
                    </Modal.Title>
                </Modal.Header>
                <Button variant="link" onClick={onClickModal}>
                    <Modal.Body>
                        <img style={image} src={`/images/charging-box-offer.png`} />
                    </Modal.Body>
                </Button>
            </Modal>
            <div style={overlay}>
                <div className="container-fluid">
                    <img style={logo} src="/images/alegoel-logga.png" />
                    <h1 className="mb-1" style={heading}>Alegos EL</h1>
                    <h2 style={secondHeading}><i>Din elektriker för små såväl som stora installationer</i></h2>
                    <div className="d-flex" style={{ justifyContent: 'center' }}>
                        <img className="mt-4 seal d-block" style={seal} src="./images/sigill.png" />
                        <img className="seal d-block" style={seal} src="./images/branschvinnare2023.png" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Start;


