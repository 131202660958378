import ReactDOMServer from 'react-dom/server'
import Resizer from "react-image-file-resizer";

function buildEmail(chargingBox, color, chargingCable, options, totalPrice, personalInformation) {
    const referenceNumber = new Date().getTime();

    function toCurrency(price) {
        return new Intl.NumberFormat("sv-SE", {
            style: "currency",
            currency: "SEK"
        }).format(price);
    }

    const base64File = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1000,
                1000,
                "JPEG",
                90,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });


    async function getEmailContent() {
        const imageUrl = await base64File(personalInformation.image)

        const emailContent = (
            <div>
                <h2>Beställning #{referenceNumber}</h2>

                <h3>Laddbox</h3>
                <p>{chargingBox.title} ${toCurrency(chargingBox.price)}</p>

                <h3>Tillval</h3>
                {options.length > 0 ? options.map((option) =>
                    <p key={option.id}>{option.name} {toCurrency(option.price)}</p>) : <p>Inga tillval valda</p>
                }

                {chargingBox.chargingCables.length > 0 &&
                    <>
                        <h3>Laddkabel</h3>
                        <p>{chargingCable ? chargingCable.name + " " + toCurrency(chargingCable.price) : 'Laddkabel ej vald'}</p>
                    </>
                }

                {color && <><h3>Färg</h3> <p>{color.name}</p></>}

                <div>
                    <h3>Totalpris</h3>
                    <p>{toCurrency(totalPrice)}</p>
                </div>

                <div>
                    <h2>Personuppgifter</h2>
                    <p>{personalInformation.firstName} {personalInformation.surname} ({personalInformation.personalNumber})</p>
                    <p>Telefonnummer: {personalInformation.phoneNumber}</p>
                    <p>Mejl: {personalInformation.email}</p>
                    <p>Adress: {personalInformation.streetAddress}, {personalInformation.city}, {personalInformation.postNumber}</p>
                    <p>Fastighetsbeteckning: {personalInformation.propertyDesignation}</p>
                </div>

                <div>
                    <h2>Bild där laddbox ska installeras</h2>
                    <div dangerouslySetInnerHTML={{ __html: `<img src="${imageUrl}" />` }}></div>
                </div>
            </div>
        )

        let emailContentAsHTML = ReactDOMServer.renderToString(emailContent)
        emailContentAsHTML = emailContentAsHTML.replace("$", "")

        return { emailContent: emailContentAsHTML, subject: `Order ${personalInformation.firstName} ${personalInformation.surname} #${referenceNumber}` }

    }
    return getEmailContent();

}

export { buildEmail }
