import React from "react";

const containerWrapper = {
    backgroundColor: '#434173',
    color: 'white',
}

const icon = {
    fontSize: 50,
}

function Footer() {
    return (
        <div id="footer" style={containerWrapper}>
            <div className="container">
                <div className="p-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            <a href="https://www.instagram.com/alegoosel" target="_blank">
                                <i style={icon} className="w-30 bi bi-instagram"></i>
                            </a>
                            <p>Copyright © 2017 Alegos EL AB</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Footer;


