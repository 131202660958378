import React from "react";
import Form from 'react-bootstrap/Form';

function Checkbox({ label, selections, setSelection, options }) {
    const heading = {
        fontWeight: 600,
        color: "#CF9447",
        fontSize: 20
    }

    const checkbox = {
        borderColor: '#6F8AA2',
        borderStyle: 'solid',
        padding: 6,
        borderRadius: 6,
        borderWidth: 2,
        color: 'black',
        fontSize: 18
    }


    function onChange(event) {
        const selectedOption = options.find((option) => option.id == event.target.value)
        const hasAlready = selections.some((selectionId) => selectionId == selectedOption.id);
        if (hasAlready) {
            const removeSelected = selections.filter((selectionId) => selectionId != selectedOption.id)
            setSelection([
                ...removeSelected
            ])
        } else {
            setSelection([
                ...selections,
                selectedOption.id
            ])
        }
    }

    function isSelected(selections, option) {
        return selections.some((selectionId) => selectionId == option.id);
    }

    if (options.length == 0) return null

    return (
        <Form>
            <Form.Label style={heading}>{label}</Form.Label>

            {options.map((option) =>
                <div style={checkbox} key={option.id + option.name} className="mb-3">
                    <Form.Check
                        feedbackTooltip={true}
                        key={option.id + option.name}
                        id={option.id + option.name}
                        onChange={onChange}
                        inline
                        label={option.name + ' ' + option.price + ' kr'}
                        type={'checkbox'}
                        value={option.id}
                        checked={isSelected(selections, option)}
                    />
                </div>
            )
            }
        </Form >
    );
}

export default Checkbox;


