import React, { useEffect, useState, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { getOrder } from "../../sessionstorage"
import axios from "axios"
import { buildEmail } from "./buildEmail";
import chargingBoxes from "../../charging-boxes.json";
import { useNavigate } from "react-router-dom";
import Footer from "../../component/Footer";

function OrderOverview() {
    const order = getOrder()
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const chargingBox = chargingBoxes.find((chargingBox) => chargingBox.id === order.chargingBoxId);
    const options = chargingBox.options.filter((option) => order.optionsId.includes(option.id));
    const color = chargingBox.colors.find((color) => color.id === order.colorId);
    const chargingCable = chargingBox.chargingCables.find((cable) => cable.id === order.chargingCableId);

    const totalPrice = chargingBox.price + (chargingCable ? chargingCable.price : 0) + options
        .map((option) => option.price)
        .reduce((p1, p2) => p1 + p2, 0);

    const [isValidated, setIsValidated] = useState(false);
    const [personalInformation, setPersonalInformation] = useState({
        firstName: '',
        surname: '',
        propertyDesignation: '',
        personalNumber: '',
        phoneNumber: '',
        email: '',
        streetAddress: '',
        city: '',
        postNumber: '',
        image: undefined
    });

    function toCurrency(price) {
        return new Intl.NumberFormat("sv-SE", {
            style: "currency",
            currency: "SEK"
        }).format(price);
    }

    const image = {
        maxWidth: '80%',
        backgroundColor: '#434173',
        borderRadius: 20
    }

    const backButton = {
        color: 'white',
        textDecoration: 'none'
    }

    const formLabel = {
        color: "#CF9447",
        fontWeight: 700,
        fontSize: 17
    }

    const containerFluid = {
        backgroundColor: '#434173'
    }

    const selection = {
        borderColor: 'rgb(111, 138, 162)',
        borderStyle: 'solid',
        padding: 6,
        borderRadius: 6,
        color: 'white'
    }

    const onUploadImage = (event) => {
        if (event.target?.files?.length > 0) {
            const file = event.target.files[0]

            setPersonalInformation({
                ...personalInformation,
                image: file
            })
        }
    }

    const onSendOrder = (event) => {
        event.preventDefault();

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();

            setIsValidated(true)
            return;
        }

        const config = {
            headers: {
                Authorization: `Bearer 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`
            }
        };

        buildEmail(chargingBox, color, chargingCable, options, totalPrice, personalInformation).then((email) => {
            axios.post('https://fbzlvgpm6gmzy3c2g465txeupy0vjpjz.lambda-url.eu-north-1.on.aws/', email, config)
                .then(ignored => console.log("Sent email"), (e) => console.error(`Error something went wrong`, e));

            navigate("/order-bekraftelse", { replace: true })
        }).catch((error) => {
            console.error(error)
        })

    }

    return (
        <>
            <div className="container-fluid" style={containerFluid}>
                <div className="container pt-5 pb-5">
                    <a href={`/#/laddbox/${chargingBox.id}`} style={backButton}>
                        <i className="bi bi-arrow-left"></i> Tillbaka
                    </a>
                    <div className="p-2">
                        <div className="row">
                            <h2 className='text-center mb-5'>DIN ORDER</h2>
                            <div className="col-sm-6 col-12 d-flex justify-content-center mb-5">
                                <img className="img-fluid" style={image} src={`/images/${chargingBox.image}`} />
                            </div>

                            <div className="col-sm-6 col-12 d-flex flex-column justify-content-center mb-5">
                                <h3>Laddbox</h3>
                                <p style={selection}>{chargingBox.title} {toCurrency(chargingBox.price)}</p>
                                <h3>Tillval</h3>
                                {options.length > 0 ? options.map((option) =>
                                    <p key={option.id} style={selection}>{option.name} {toCurrency(option.price)}</p>) :
                                    <p style={selection}>Inga tillval valda</p>
                                }

                                {chargingBox.chargingCables.length > 0 &&
                                    <>
                                        <h3>Laddkabel</h3>
                                        <p style={selection}>{chargingCable ? chargingCable.name + " " + toCurrency(chargingCable.price) : 'Laddkabel ej vald'}</p>
                                    </>
                                }

                                {color && <><h3>Färg</h3> <p style={selection}>{color.name}</p></>}

                                <div>
                                    <h3>Totalpris</h3>
                                    <p style={selection}>{toCurrency(totalPrice)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pt-5 pb-5">
                <div>
                    <h2 className='text-center mb-5'>DINA UPPGIFTER</h2>
                    <div className="col-12">
                        <Form noValidate validated={isValidated} onSubmit={onSendOrder}>
                            <div className="row mb-4 d-flex justify-content-center">
                                <Form.Group className="mb-2" as={Col} md="4">
                                    <Form.Label style={formLabel}>Förnamn</Form.Label>
                                    <Form.Control
                                        required
                                        value={personalInformation.firstName}
                                        onChange={e => setPersonalInformation({
                                            ...personalInformation,
                                            firstName: e.target.value
                                        })}
                                        type="text"
                                        placeholder="Förnamn"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Fyll i förnamn
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label style={formLabel}>Efternamn</Form.Label>
                                    <Form.Control
                                        required
                                        value={personalInformation.surname}
                                        onChange={e => setPersonalInformation({
                                            ...personalInformation,
                                            surname: e.target.value
                                        })}
                                        type="text"
                                        placeholder="Efternamn"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Fyll i efternamn
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="row mb-3 d-flex justify-content-center">
                                <Form.Group className="mb-2" as={Col} md="4">
                                    <Form.Label style={formLabel}>Personnummer</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            pattern="([0-9]{6,8}(-){0,1}([0-9]{4}))"
                                            value={personalInformation.personalNumber}
                                            onChange={e => setPersonalInformation({
                                                ...personalInformation,
                                                personalNumber: e.target.value
                                            })}
                                            type="text"
                                            placeholder="Personnummer"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Fyll i personnummer med 10 eller 12 siffror.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label style={formLabel}>Fastighetsbeteckning</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="text"
                                            value={personalInformation.propertyDesignation}
                                            onChange={e => setPersonalInformation({
                                                ...personalInformation,
                                                propertyDesignation: e.target.value
                                            })}
                                            placeholder="Fastighetsbeteckning"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Fyll i fastighetsbeteckning
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            <div className="row mb-4 d-flex justify-content-center">
                                <Form.Group className="mb-2" as={Col} md="4">
                                    <Form.Label style={formLabel}>Gatuadress</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="text"
                                            value={personalInformation.streetAddress}
                                            onChange={e => setPersonalInformation({
                                                ...personalInformation,
                                                streetAddress: e.target.value
                                            })}
                                            placeholder="Gatuadress"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Fyll i gatuadress
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label style={formLabel}>Ort</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            value={personalInformation.city}
                                            onChange={e => setPersonalInformation({
                                                ...personalInformation,
                                                city: e.target.value
                                            })}
                                            type="text"
                                            placeholder="Ort"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Fyll i ort
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </div>

                            <div className="row mb-4 d-flex justify-content-center">
                                <Form.Group className="mb-2" as={Col} md="4">
                                    <div>
                                        <Form.Label style={formLabel}>Postnummer</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="number"
                                                value={personalInformation.postNumber}
                                                onChange={e => setPersonalInformation({
                                                    ...personalInformation,
                                                    postNumber: e.target.value
                                                })}
                                                placeholder="Postnummer"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Fyll i postnummer.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-2" as={Col} md="4">
                                    <div>
                                        <Form.Label style={formLabel}>Telefonnummer</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="number"
                                                value={personalInformation.phoneNumber}
                                                onChange={e => setPersonalInformation({
                                                    ...personalInformation,
                                                    phoneNumber: e.target.value
                                                })}
                                                placeholder="Telefonnummer"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Fyll i telefonnummer.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </div>
                                </Form.Group>

                            </div>
                            <div className="row mb-4 d-flex justify-content-center">
                                <Form.Group className="mb-2" as={Col} md="4">
                                    <div>
                                        <Form.Label style={formLabel}>Mejl</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="email"
                                                value={personalInformation.email}
                                                onChange={e => setPersonalInformation({
                                                    ...personalInformation,
                                                    email: e.target.value
                                                })}
                                                placeholder="Mejl"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Fyll i mejl.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-2" as={Col} md="4">
                                    <div>
                                        <Form.Label style={formLabel}>Bild där laddbox ska installeras</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                accept="image/png, image/jpeg"
                                                id="input-file"
                                                type="file"
                                                onChange={onUploadImage}
                                                required
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                Välj en bild
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="row mb-3 d-flex justify-content-center">
                                <div className="mb-2 col-md-8 text-end">
                                    <Button className="w-auto" type="submit">Skicka beställning</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default OrderOverview;


