
const ORDER_KEY = 'ORDER'
const BANNER_KEY = 'IS_SHOWN_BANNER'

function getOrder() {
    const orderAsString = window.sessionStorage.getItem(ORDER_KEY)
    return JSON.parse(orderAsString)
}

function setOrder(order) {
    window.sessionStorage.setItem(ORDER_KEY, JSON.stringify(order));
}

function removeOrder() {
    window.sessionStorage.removeItem(ORDER_KEY);
}

function isShowBanner() {
    const bannerAsString = JSON.parse(window.sessionStorage.getItem(BANNER_KEY))
    if (bannerAsString === false) {
        return false;
    } else {
        return true;
    }
}

function setIsShowBanner(isShow) {
    window.sessionStorage.setItem(BANNER_KEY, JSON.stringify(isShow));
}

export { getOrder, setOrder, removeOrder, isShowBanner, setIsShowBanner }
