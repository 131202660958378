import React, { useEffect } from "react";
import Footer from "../../component/Footer";
import { removeOrder } from "../../sessionstorage"

function OrderConfirmation() {
    useEffect(() => {
        removeOrder()
    }, [])

    const footer = {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%'
    }

    return (
        <>
            <div className="container text-center">
                <div className="row mt-5">
                    <div className="col-lg-6 col-12 p-5">
                        <h2>Order bekräftelse</h2>

                        <p>Tack för din order. Vi hör av oss!</p>
                        <p>Med vänliga hälsningar, <br /> Alegos EL</p>
                    </div>
                    <div className="col-lg-6 col-12">
                        <img className="img-fluid" src={`/images/alegoel-logga.png`} />
                    </div>
                </div>

            </div>

            <div style={footer}>
                <Footer />
            </div>
        </>
    );
}

export default OrderConfirmation;


