import React from "react";

const icon = {
    fontSize: 50,
    color: 'rgb(67, 65, 115)'
}

const text = {
    fontSize: 30
}

function ContactUs() {
    return (
        <div id="contact-us" className="container content">
            <div className="p-5">
                <h2 className='text-center'>KONTAKTA OSS</h2>

                <div className="row mb-5">
                    <div className="col-12 col-sm-4 d-flex flex-column align-items-center">
                        <div><i style={icon} className="bi bi-geo-alt"></i></div>
                        <p style={text}>Liljeholmen</p>
                    </div>
                    <div className="col-12 col-sm-4 d-flex flex-column align-items-center">
                        <div><i style={icon} className="bi-phone"></i></div>
                        <p style={text}>076 932 18 25</p>
                    </div>
                    <div className="col-12 col-sm-4 d-flex flex-column align-items-center">
                        <div><i style={icon} className="bi bi-envelope"></i></div>
                        <p style={text}>info@alegosel.se</p>
                    </div>
                </div>
                <div className="row">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2036.2075972518214!2d18.026794078053044!3d59.31279037460636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f77c66835c0a1%3A0x5cebc698b0958270!2sLiljeholmsv%C3%A4gen%201%2C%20117%2061%20Stockholm!5e0!3m2!1ssv!2sse!4v1700337131471!5m2!1ssv!2sse" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;


