import React, { useEffect, useState } from "react";
import { getOrder } from "../../sessionstorage"
import chargingBoxes from "../../charging-boxes.json";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import RadioBox from "../charging-box-detail/RadioBox";

function CableHolderSelector() {
    const order = getOrder()
    const navigate = useNavigate();
    const { id } = useParams()

    const [colorId, setColorId] = useState(1)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const selectedBox = chargingBoxes.find((box) => box.id === parseInt(id));

    function navigateOrderView() {
        /*setOrder({
            chargingBoxId: chargingBoxId,
            optionsId: optionsId,
            colorId: colorId,
            chargingCableId: chargingCableId
        })*/

        navigate("/bestallningsoversikt")
    }

    const image = {
        width: '25%'
    }

    return (
        <>
            <div className="container content">
                <h2 className="text-center">Välj Kabelhållare</h2>

                <div className="row">
                    <img style={image} src={`/images/kabelfaste.png`} />
                    <RadioBox options={[selectedBox.cableHolders[1]]} selection={colorId} setSelection={setColorId} />

                    <img style={image} src={`/images/u-hook.png`} />


                    <RadioBox options={[selectedBox.cableHolders[0]]} selection={colorId} setSelection={setColorId} />

                </div>



                <div className="d-flex justify-content-end">
                    <button onClick={navigateOrderView} type="button" className="btn btn-primary mb-4">Välj färg</button>
                </div>
            </div>
        </>
    );
}

export default CableHolderSelector;


