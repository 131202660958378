import React from "react";
import Form from 'react-bootstrap/Form';

function List({ icon, label, items }) {
    const heading = {
        fontWeight: 600,
        color: "#CF9447",
        fontSize: 20
    }

    const row = {
        borderColor: '#6F8AA2',
        borderStyle: 'solid',
        padding: 6,
        borderRadius: 6,
        borderWidth: 2,
        color: 'black',
        fontSize: 18,
        marginBottom: 16
    }

    if (items.length === 0) {
        return null
    }

    return (
        <Form>
            <Form.Label style={heading}>{label}</Form.Label>

            {items.map((item, i) =>
                <div key={i} style={row}>
                    {icon} {item}
                </div>
            )}
        </Form>
    );
}

export default List;


