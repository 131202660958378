import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Checkbox from "./Checkbox";
import List from "./List";
import chargingBoxes from "../../charging-boxes.json";
import DropDown from "./Dropdown";
import { useNavigate } from "react-router-dom";
import { getOrder, setOrder } from "../../sessionstorage"
import RadioBox from "./RadioBox";
import Footer from "../../component/Footer";

const backButton = {
    color: 'white',
    textDecoration: 'none'
}

const image = {
    width: '45%'
}

const price = {
    color: 'rgb(207, 148, 71)',
    fontWeight: 600,
    fontSize: 30
}

const imageWrapper = {
    backgroundColor: '#434173',
    borderRadius: 20
}

const tabs = {
    fontWeight: 600,
    fontSize: 20,
}

const text = {
    color: 'black'
}


function ChargingBoxDetail() {
    const { id } = useParams()
    const navigate = useNavigate()

    const selectedBox = chargingBoxes.find((box) => box.id === parseInt(id));

    const [optionsId, setOptionsId] = useState([])
    const [colorId, setColorId] = useState(1)
    const [chargingBoxId, setChargingBoxId] = useState()
    const [chargingCableId, setChargingCableId] = useState()

    const [totalPrice, setTotalPrice] = useState(0)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const optionsTotalPrice = selectedBox.options
            .filter((option) => optionsId.includes(option.id))
            .map((option) => option.price)
            .reduce((p1, p2) => p1 + p2, 0)

        const chargingCable = selectedBox.chargingCables.find((cable) => cable.id === chargingCableId);
        const chargingCablePrice = !!chargingCable ? chargingCable.price : 0

        setTotalPrice(selectedBox.price + optionsTotalPrice + chargingCablePrice)

    }, [id, optionsId, chargingCableId])

    useEffect(() => {
        const order = getOrder();

        if (!!order && order.chargingBoxId === parseInt(id)) {
            setChargingBoxId(order.chargingBoxId)
            setOptionsId(order.optionsId)
            setColorId(order.colorId)
        } else {
            setChargingBoxId(selectedBox.id)
            setOptionsId([])
        }
    }, [id])

    function navigateOrderView() {
        setOrder({
            chargingBoxId: chargingBoxId,
            optionsId: optionsId,
            colorId: colorId,
            chargingCableId: chargingCableId
        })

        navigate("/bestallningsoversikt")
    }

    function toCurrency(price) {
        return new Intl.NumberFormat("sv-SE", {
            style: "currency",
            currency: "SEK"
        }).format(price);
    }

    return (
        <div>
            <div className="container-fluid" style={{ backgroundColor: '#434173' }}>
                <div className="container pt-5 pb-5" style={{ minHeight: 500 }}>

                    <div className="row mb-2">
                        <div className="col-6 d-flex align-items-center">
                            <a href="/#/#charging-boxes" style={backButton}>
                                <i className="bi bi-arrow-left"></i> Tillbaka
                            </a>

                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <DropDown />
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-sm-6">
                            <div style={imageWrapper} className="text-center mb-3">
                                <img style={image} src={`/images/${selectedBox.image}`} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <h1 className='text-center'>{selectedBox.title}</h1>
                            <p style={{ color: 'white' }}>{selectedBox.preamble}</p>

                            <p style={{ color: 'white' }}>
                                Grön Teknik Bidrag
                                Installerar du laddstationen i hemmet så kan du få upp till 50% av investeringskostnaden (max 50.000 kr) i statligt stöd.
                            </p>

                            <p style={price}>Totalpris: {toCurrency(totalPrice)}</p>
                            <button onClick={navigateOrderView} type="button" className="btn btn-primary mb-4 col-6">Beställ</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="container pb-5 pt-5 d-flex flex-column">
                    <div className="row mb-5">
                        <div className="col-sm-6">
                            <div>
                                <div className="tabs">
                                    <Tabs
                                        style={tabs}
                                        defaultActiveKey="description"
                                        className="mb-3"
                                        fill
                                    >
                                        <Tab eventKey="description" title="Beskrivning">
                                            <table className='table'>
                                                <thead>
                                                    {selectedBox.description.map((desc, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <p style={text}>{desc}</p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </thead>
                                            </table>
                                        </Tab>

                                        <Tab eventKey="specification" title="Specifikation">
                                            <table className='table'>
                                                <thead>
                                                    {selectedBox.specification.map((spec, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="w-50"><p style={text}>{spec[0]}</p></td>
                                                                <td className='w-50 text-end'><p style={text}>{spec[1]}</p></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </thead>
                                            </table>
                                        </Tab>
                                    </Tabs>
                                </div>


                                <div>
                                    {selectedBox.texts.map((text, i) =>
                                        <div key={i}>
                                            <h2>{text[0]}</h2>
                                            <p style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: text[1] }}></p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <Checkbox label="Välj tillval:" options={selectedBox.options} selections={optionsId} setSelection={setOptionsId} />

                            <RadioBox label="Välj färg:" options={selectedBox.colors} selection={colorId} setSelection={setColorId} />

                            <RadioBox label="Välj laddkabel:" options={selectedBox.chargingCables} selection={chargingCableId} setSelection={setChargingCableId} />

                            <List icon={<i style={{ color: '#16ff0a' }} className="bi bi-check2-square"></i>} label="Installationen inkluderar:" items={selectedBox.installationIncludes} />
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ChargingBoxDetail;


