import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { HashLink } from 'react-router-hash-link';

function NavigationBar() {
    const logo = {
        width: 50
    }

    const navToggle = {
        backgroundColor: 'white'
    }

    return (
        <Navbar id="navbar" expand="lg">
            <Container>
                <Navbar.Brand>
                    <HashLink to="/#start">
                        <img style={logo} src="/images/alegoel-logga.png" />
                    </HashLink>
                </Navbar.Brand>

                <Navbar.Toggle style={navToggle} aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="m-auto text-center">
                        <HashLink to="/#services">TJÄNSTER</HashLink>
                        <HashLink to="/#charging-boxes">LADDBOXAR</HashLink>
                        <HashLink to="/#price-list">PRISLISTA</HashLink>
                        <HashLink to="/#about-us">OM OSS</HashLink>
                        <HashLink to="/#contact-us">KONTAKTA OSS</HashLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavigationBar;


