import React, { useEffect, useState } from "react";
import { getOrder } from "../../sessionstorage"
import chargingBoxes from "../../charging-boxes.json";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import RadioBox from "../charging-box-detail/RadioBox";

function ColorSelector() {
    const order = getOrder()
    const navigate = useNavigate();
    const { id } = useParams()

    const [colorId, setColorId] = useState(1)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const selectedBox = chargingBoxes.find((box) => box.id === parseInt(id));

    function navigateOrderView() {
        /*setOrder({
            chargingBoxId: chargingBoxId,
            optionsId: optionsId,
            colorId: colorId,
            chargingCableId: chargingCableId
        })*/

        navigate("/bestallningsoversikt")
    }

    const image = {
        width: '50%'
    }

    return (
        <>
            <div className="container content">
                <h2 className="text-center">Välj färg</h2>
                <div className="row">
                    <div className="col">
                        <div className="text-center">
                            <img style={image} src={`/images/zaptec.png`} />
                        </div>
                    </div>
                    <div className="col">
                        <RadioBox options={selectedBox.colors} selection={colorId} setSelection={setColorId} />
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                    <button onClick={navigateOrderView} type="button" className="btn btn-primary mb-4">Välj färg</button>
                </div>
            </div>
        </>
    );
}

export default ColorSelector;


