import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { getOrder } from "../../sessionstorage"
import chargingBoxes from "../../charging-boxes.json";
import { useNavigate } from "react-router-dom";
import Footer from "../../component/Footer";
import ChargingCable from "./CharingCable";
import ColorSelector from "./ColorSelector";
import CableHolderSelector from "./CableHolderSelector";

function OrderSelection() {
    const order = getOrder()
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className="container">

                <CableHolderSelector />

                <ColorSelector />
                <ChargingCable />

            </div>

        </>
    );
}

export default OrderSelection;


