import React from "react";
import { HashLink } from 'react-router-hash-link';

const image = {
    width: '60%',
    height: 150,
    marginBottom: 15
}

const heading = {
    fontSize: 24,
    marginBottom: 15
}

function Services() {
    return (
        <div id="services" className="container content">
            <h2 className='text-center'>TJÄNSTER</h2>

            <div className="row mb-5">
                <p>Alegos El arbetar efter principen att kundens önskemål ska uppnås med hög kundnöjdhet. Vi vill alltid säkerställa att kvalitet i våra tjänster levereras och uppnår våra kunders önskemål.</p>

                <p>Alegos El utför arbeten som hjälper kunden att uppfylla deras önskemål i form av elarbeten. Det utförda arbetet ska vara enkelt att underhålla och felsöka.</p>

                <p>För att säkerställa våra kvalité arbetar vi regelbundet med kvalitetskontroller och kundkommunikation.</p>
            </div>

            <div className="row">
                <div className="col-sm-4 col-12 d-flex flex-column align-items-center">
                    <h3 style={heading}>Elinstallation</h3>
                    <img className="rounded" style={image} src="./images/elinstallation.jpg" />
                    <p className='text-center'>Vi utför alla slags elinstallationer inom lågspänningsområdet, t.ex installation av jordfelsbrytare, villor, entreprenader, kontor och badrumsrenoveringar.</p>
                </div>
                <div className="col-sm-4 col-12 d-flex flex-column align-items-center">
                    <h3 style={heading}>Belysning</h3>
                    <img className="rounded" style={image} src="./images/lampa.jpg" />
                    <p className='text-center'>Vi installerar all typ av belysning inom industri, kontor, bostäder, idrottsanläggningar etc.</p>
                </div>
                <div className="col-sm-4 col-12 d-flex flex-column align-items-center">
                    <h3 style={heading}>Laddboxar</h3>
                    <img className="rounded" style={image} src="./images/charging-station.jpg" />
                    <p className='text-center'>
                        Vi installerar även laddboxar. Läs mer om det <HashLink style={{ display: 'inline-block', width: 'fit-content' }} to="/#charging-boxes">här.</HashLink>
                    </p>
                </div>
            </div>

        </div>
    );
}

export default Services;


