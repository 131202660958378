import './App.css';
import Start from './pages/start';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter, Routes, Route } from "react-router-dom";
import Services from './pages/services';
import AboutUs from './pages/about-us';
import PriceList from './pages/price-list';
import ContactUs from './pages/contact-us';
import ChargingBox from './pages/charging-box';
import ChargingBoxDetail from './pages/charging-box-detail';
import OrderOverview from './pages/order-overview';
import NavigationBar from "./component/NavigationBar";
import Footer from "./component/Footer";
import {
    createHashHistory
} from "history";
import OrderConfirmation from "./pages/order-confirmation";
import OrderSelection from "./pages/order-selection";

function App() {
    const history = createHashHistory();

    function mainPages() {
        return <>
            <NavigationBar />
            <Start />
            <Services />
            <ChargingBox />
            <PriceList />
            <AboutUs />
            <ContactUs />
            <Footer />
        </>;
    }

    return (
        <div style={{ height: '100vh' }}>
            <HashRouter history={history} basename='/'>
                <Routes>
                    <Route index path='/' element={mainPages()} />
                    <Route path='/laddbox/:id' element={<><NavigationBar /><ChargingBoxDetail /></>} />
                    <Route path='/bestallningsoversikt' element={<><NavigationBar /><OrderOverview /></>} />
                    <Route path='/order-bekraftelse'
                        element={<><NavigationBar /><OrderConfirmation /></>} />
                    <Route path='/gab/:id'
                        element={<><NavigationBar /><OrderSelection /></>} />
                </Routes>
            </HashRouter>
        </div>
    );
}

export default App;


