import React, { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { useParams } from "react-router-dom";
import chargingBoxes from "../../charging-boxes.json";

function DropDown() {
    const { id } = useParams()
    const [currentBox, setCurrentBox] = useState();

    const toggle = {
        backgroundColor: 'rgb(0, 119, 167)',
        width: 230,
        fontSize: 20
    }

    useEffect(() => {
        const currentBox = chargingBoxes.find((box) => box.id == id);
        if (currentBox) {
            setCurrentBox(currentBox)
        }
    }, [id])

    return (
        <Dropdown>
            <Dropdown.Toggle style={toggle}>
                {currentBox?.title}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {chargingBoxes.map((box) =>
                    <Dropdown.Item key={box.id} href={`/#/laddbox/${box.id}`}>{box.title}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default DropDown;


